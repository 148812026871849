@media (min-width: 1400px) {
  .container {
    max-width: 1140px; /* Adjust this value as needed */
  }
}
.carousel-caption{
  bottom: auto;
}
a {
    text-decoration: none;
    transition: all .2s ease-in-out 0s;
}
.big_container{
  padding-top: 50px;
    padding-bottom: 100px;
}
.custom-rows{
    align-items: center;
}
.img-container{
    padding: 0% 15%;
}
.service-bl.buro {
  background-image: url(./img/buroo.webp);
  background-size: cover;
  background-position: center;
  padding: 200px 50px;
  text-align: center;
  position: relative;
  color: white; 
  transition: background 0.5s ease-in-out; 
}
.service-bl.erreich {
    background-image: url(./img/accessibility.webp);
    background-size: cover;
    background-position: center;
    padding: 200px 50px;
    text-align: center;
    position: relative;
    color: white; 
    transition: background 0.5s ease-in-out; 
  }
  .service-bl.sprachen {
    background-image: url(./img/bureau.webp);
    background-size: cover;
    background-position: center;
    padding: 200px 50px;
    text-align: center;
    position: relative;
    color: white; 
    transition: background 0.5s ease-in-out; 
  }
  .service-bl.kosten {
    background-image: url(./img/slider-img2.webp);
    background-size: cover;
    background-position: center;
    padding: 200px 50px;
    text-align: center;
    position: relative;
    color: white; 
    transition: background 0.5s ease-in-out; 
  }
  
  .col-overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35); 
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    z-index: 2;
    transition: background 0.3s ease-in-out; 
  }
  
  .service-bl:hover .col-overlay-content {
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  .service-bl a {
    display: block;
    height: 100%;
    width: 100%;
    color: inherit;
    text-decoration: none;
    position: relative;
    z-index: 3; 
  }
.service-title{
    font-size: 30px;
    font-weight: 600;
}
  
.second-title{
    font-size: 40px;
    text-align: center;
    margin: 40px 0px;
}

.service-bl.border_box{
    border-radius: 40px 40px 40px 40px;
}
.col-overlay-content.border_box{
    border-radius: 40px 40px 40px 40px;
}
.first-serv{
    padding: 0px 0px 10px 0px;
}
.left-serv{
    padding: 10px 10px 0px 0px;
}
.center-serv{
    padding: 10px 10px 0px 10px;
}
.right-serv{
    padding: 10px 0px 0px 10px;
}
.custom-footer{
    padding-bottom: 15px;
    padding-top: 60px;
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
.custom-footer{
    padding-bottom: 15px;
    padding-top: 60px;
}
  }
  @media only screen and (min-width: 992px) {
    /* For desktop: */
.custom-footer{
    padding-bottom: 50px;
    padding-top: 90px;
}
  }
@media only screen and (min-width: 1200px) {
    /* For desktop: */
.custom-footer{
    padding-bottom: 50px;
    padding-top: 120px;
}
  }
  .social-container{
    margin-top: 30px;
  }
.footer-section{
    background-color: #1d1d1d;
}
.copyright-section{
    padding-top: 0px;
    padding-bottom: 25px;
}
.custom-footer ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
}
.custom-footer ul li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.custom-footer ul li a {
    padding-top: 8px;
    padding-bottom: 9px;
    text-underline-offset: 10px;
    text-underline-position: under;
    line-height: 1.2;
    color: #9d9d9d;
}
.custom-footer ul li a:hover {
    color: #fff;
}
.logo-title{
    color: #fff;
}
.custom-footer p {
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
}
p.content {
    color: #9d9d9d;
}
.widget_contact_list .contact-item + .contact-item {
    margin-top: 12px;
}
.custom-footer .social-icons a {
    color: #9d9d9d;
    border-color: #9d9d9d;
}
.custom-footer a.fa.border-icon {
    margin-left: 3px;
    margin-right: 3px;
}


p.social_title{
    font-size: 16px;
    margin-bottom: 6px;
    line-height: 30px;
    margin-bottom: 20px;
}
.text_align{
    text-align: center;
}
.service-bl .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.404); /* Adjust the opacity and color as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }
  .cont {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width:768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
  }

  header .intern-intro-text {
    padding-top: 200px;
    padding-bottom: 250px;
    text-align: center;
  }
  .intern-intro-text h1{
    margin-bottom: 50px;
  }
  .intro-help {
    display: table;
    width: 100%;
    padding: 0;
    background: url(./img/help-header.webp) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .intro-help .overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  .intro-help h1 {
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 70px;
  }
  .intro-help h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  @media (max-width: 768px) {

    .intro-help h1 {
       font-size: 32px;
   }
}
  .intro-help p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
   .intro-help {
    display: table;
    width: 100%;
    padding: 0;
    background: url(./img/help-header.webp) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .intro-help .overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  .intro-help {
    display: table;
    width: 100%;
    padding: 0;
    background: url(./img/help-header.webp) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .intro-help .overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  .intro-help h1 {
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 70px;
  }
  .intro-help h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  @media (max-width: 768px) {

    .intro-help h1 {
       font-size: 32px;
   }
}
  .intro-impre p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
   .intro-impre {
    display: table;
    width: 100%;
    padding: 0;
    background: url(./img/print.webp) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .intro-impre .overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  .carousel-caption h1 {
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 70px;
  }
  .intro-impre h1 {
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 70px;
  }
  .intro-impre h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  @media (max-width: 768px) {

    .intro-impre h1 {
       font-size: 32px;
   }
   .carousel-caption h1{
    font-size: 32px;
   }
}
@media (max-width: 992px) {

  .intro-impre h1 {
     font-size: 50px;
 }
 .carousel-caption h1{
  font-size: 50px;
 }
}
  .intro-impre p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .intro-daten {
    display: table;
    width: 100%;
    padding: 0;
    background: url(./img/protection.webp) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .intro-daten .overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  .intro-daten h1 {
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 70px;
  }
  .intro-daten h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  @media (max-width: 768px) {

    .intro-daten h1 {
       font-size: 32px;
   }
}

  .intro-weare {
    display: table;
    width: 100%;
    padding: 0;
    background: url(./img/whoweare.webp) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .intro-weare .overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  .intro-weare h1 {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 70px;
  }
  .intro-weare h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  @media (max-width: 768px) {

    .intro-weare h1 {
       font-size: 32px;
   }
}
  .intro-weare p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .intro-contact {
    display: table;
    width: 100%;
    padding: 0;
    background: url(./img/contact_img.webp) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .intro-contact .overlay {
    background: rgba(0, 0, 0, 0.4);
  }
  .intro-contact h1 {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .intro-contact h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  @media (max-width: 768px) {

    .intro-contact h1 {
       font-size: 32px;
   }
}
  .intro-contact p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .intern-rows{
    margin: 40px 0px;
  }

  .team-member-container {
    text-decoration: none;
    color: inherit;
  }
  
  .team-member-container:hover {
    color: #000; /* Change the color on hover */
  }
  
  .team-member {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  
  .team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .team-member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
    transition: transform 0.3s;
  }
  
  .team-member:hover .team-member-image {
    transform: scale(1.1);
  }
  
  .team-member-position {
    font-weight: bold;
    color: gray;
    margin-top: 10px;
  }
  
  .team-member p {
    margin: 0;
  }
  .copyright-section .text-center{
    color: white;
  }
  /* button style */
  .button {
    position: relative;
    padding: 16px 30px;
    font-size: 1.5rem;
    color: var(--color);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;
    text-shadow: 0 0 15px var(--color);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    transition: 0.5s;
    z-index: 1;
  }
  @media (max-width: 768px) {

    .button {
       font-size: 0.7rem;
       padding: 10px 15px;
   }
}
  
  .button:hover {
    color: #fff;
    border: 2px solid rgba(0, 0, 0, 0);
    box-shadow: 0 0 0px var(--color);
  }
  
  .button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color);
    z-index: -1;
    transform: scale(0);
    transition: 0.5s;
  }
  
  .button:hover::before {
    transform: scale(1);
    transition-delay: 0.5s;
    box-shadow: 0 0 10px var(--color),
      0 0 30px var(--color),
      0 0 60px var(--color);
  }
  
  .button span {
    position: absolute;
    background: var(--color);
    pointer-events: none;
    border-radius: 2px;
    box-shadow: 0 0 10px var(--color),
      0 0 20px var(--color),
      0 0 30px var(--color),
      0 0 50px var(--color),
      0 0 100px var(--color);
    transition: 0.5s ease-in-out;
    transition-delay: 0.25s;
  }
  
  .button:hover span {
    opacity: 0;
    transition-delay: 0s;
  }
  
  .button span:nth-child(1),
  .button span:nth-child(3) {
    width: 40px;
    height: 4px;
  }
  
  .button:hover span:nth-child(1),
  .button:hover span:nth-child(3) {
    transform: translateX(0);
  }
  
  .button span:nth-child(2),
  .button span:nth-child(4) {
    width: 4px;
    height: 40px;
  }

  .button:hover span:nth-child(1),
  .button:hover span:nth-child(3) {
    transform: translateY(0);
  }
  
  .button span:nth-child(1) {
    top: calc(50% - 2px);
    left: -50px;
    transform-origin: left;
  }
  
  .button:hover span:nth-child(1) {
    left: 50%;
  }
  
  .button span:nth-child(3) {
    top: calc(50% - 2px);
    right: -50px;
    transform-origin: right;
  }
  
  .button:hover span:nth-child(3) {
    right: 50%;
  }
  
  .button span:nth-child(2) {
    left: calc(50% - 2px);
    top: -50px;
    transform-origin: top;
  }
  
  .button:hover span:nth-child(2) {
    top: 50%;
  }
  
  .button span:nth-child(4) {
    left: calc(50% - 2px);
    bottom: -50px;
    transform-origin: bottom;
  }
  
  .button:hover span:nth-child(4 ) {
    bottom: 50%;
  }
  /* form styles  */
  .form-box {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
  }
  
  .form-box .form-label {
    font-weight: bold;
  }
  
  .form-box .form-control {
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .form-box .btn-primary {
    background: #1e9bff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    transition: background 0.3s;
  }
  
  .form-box .btn-primary:hover {
    background: #006bb3;
  }
  
  .form-box .form-check-input:checked {
    background-color: #1e9bff;
    border-color: #1e9bff;
  }
  
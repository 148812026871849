.carousel-item img {
    height: 600px; 
    object-fit: cover;
    position: relative;
  }
  
  .carousel-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.404); /* Adjust the opacity and color as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }
  
  .carousel-item .overlay h3,
  .carousel-item .overlay p {
    margin: 0;
  }
  .nav-link{
    font-weight: 700!important;
  }
  